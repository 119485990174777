import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import taggingIcon from 'img/ui/tagging-icon.svg';
import engagementIcon from 'img/ui/engagement-icon.svg';
import userJourneyIcon from 'img/ui/user-journey-icon.svg';
import customPropertiesIcon from 'img/ui/custom-properties-icon.svg';
import { Section, Main } from './styles';

const KeyAdvantages = () => {
  const { journey, engagement, tagging, properties } = useStaticQuery(graphql`
    query {
      journey: file(relativePath: { eq: "fullstory-alternative/user-journey.png" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      engagement: file(relativePath: { eq: "fullstory-alternative/engagement-score.png" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      properties: file(relativePath: { eq: "fullstory-alternative/custom-properties.png" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tagging: file(relativePath: { eq: "fullstory-alternative/tagging.png" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Main>
      <h2>More key advantages...</h2>
      <Section>
        <Img fluid={journey.childImageSharp.fluid} alt="user-journey" title="User journey" />
        <div className="content-box">
          <img src={userJourneyIcon} alt="user-journey-icon" title="User journey" />
          <h3 className="new">User journey</h3>
          <p>
            The user journey is the path your visitors take when exploring your website. With
            LiveSession you can now discover and analyze it. This will help you improve the shopping
            funnel in your store or improve the navigation of your website.
            <br />
            <br />
            There are many uses and this tool will certainly help you improve your UX.
          </p>
        </div>
      </Section>
      <Section reverse>
        <Img
          fluid={engagement.childImageSharp.fluid}
          alt="Engagement score"
          title="Engagement score"
        />
        <div className="content-box">
          <img src={engagementIcon} alt="engagement-icon" title="Engagement score" />
          <h3>Engagement score</h3>
          <p>
            There is a way to find the most engaging sessions without browsing manually.{' '}
            <strong>Engagement score </strong>allows you to find recordings with the most activity.
            <br />
            <br />
            The rating depends on many factors, including the number of pages visited and the number
            of clicks. Engagement score will help you find the most important sessions
            automatically.
          </p>
        </div>
      </Section>
      <Section>
        <Img
          fluid={properties.childImageSharp.fluid}
          alt="custom properties"
          title="Custom properties"
        />
        <div className="content-box">
          <img src={customPropertiesIcon} alt="custom-properties-icon" title="Custom properties" />
          <h3>Custom properties</h3>
          <p>
            Would you like to identify the users on your website? Thanks to custom properties,
            you’ll know exactly who you’re watching.
            <br /> <br />
            LiveSession allows you to add custom user properties, including support tier,
            subscription plan or ID from analytics platforms. This feature is particularly helpful
            for SaaS companies, as they often offer products in a variety of pricing plans.
          </p>
        </div>
      </Section>
      <Section reverse>
        <Img fluid={tagging.childImageSharp.fluid} alt="tagging" title="Tagging" />
        <div className="content-box">
          <img src={taggingIcon} alt="tagging-icon" title="Tagging" />
          <h3>Tagging</h3>
          <p>
            Tagging is a very simple but powerful tool. It allows you to mark specific sessions to
            find them easily later on. That way you’ll keep your user recordings neatly organized.
            <br /> <br />
            Focus on most important problems and deal with them one by one. Indicate priorities for
            the development of your website.
          </p>
        </div>
      </Section>
    </Main>
  );
};

export default KeyAdvantages;
