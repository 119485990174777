import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import Layout from 'layout';
import StartUsing from 'components/ui/startUsing';
import FAQ from 'components/FAQ';
import CompareTo from 'components/CompareTo';
import { questions } from 'data/compare/fullstory/questions';
import { LIVESESSION, FULLSTORY, dataSet, icon } from 'data/compare/fullstory/table';
import Button from 'components/ui/button';
import { Header, HeadSection, Section, StyledH2, Divider } from 'components/compare/styles';
import KeyAdvantages from 'components/compare/KeyAdvantagesFullStory';
import HeadContentFullstory from 'components/compare/HeadContentFullstory';
import pricingImg from 'img/ui/pricing.svg';
import Link from 'components/ui/link';
import TrustedBy from 'components/ui/trustedBy';
import Opinion from 'components/compare/Opinion';
import CustomersRate from 'components/compare/CustomersRate';
import Banner from 'components/compare/Banner';

const FullStoryCompare = () => {
  const { pricing, hero } = useStaticQuery(graphql`
    query {
      pricing: file(relativePath: { eq: "fullstory-alternative/pricing.png" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hero: file(relativePath: { eq: "fullstory-alternative/hero.png" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout
      metaDescription="Try LiveSession, a better FullStory alternative. Choose flexible pricing, custom properties, engagement scores and get even more from your session replays."
      metaTitle="FullStory Alternative | Better Session Recordings"
      canonical="/compare/fullstory-alternative/"
    >
      <Header>
        <div className="container">
          <div className="head-content-box">
            <h1 className="line">
              <span>LiveSession</span> is a better choice than FullStory
            </h1>
            <p>Flexible plans, custom properties and much more.</p>
            <Button secondary signUp sourceID="hero">
              Sign up free
            </Button>
          </div>
          <Img
            fluid={hero.childImageSharp.fluid}
            alt="livesession vs fullstory"
            title="Compare LiveSession with FullStory"
            objectFit="contain"
            objectPosition="center"
          />
        </div>
      </Header>
      <div className="container">
        <HeadSection>
          <HeadContentFullstory />
        </HeadSection>
        <StyledH2 className="line">
          Where <span>LiveSession</span> takes the lead over FullStory
        </StyledH2>
        <Section>
          <Img fluid={pricing.childImageSharp.fluid} />
          <div className="content-box">
            <img src={pricingImg} alt="pricing-icon" title="Pricing" />
            <h3>Flexible and transparent pricing</h3>
            <p>
              You can get LiveSession for just <strong>$49 per month</strong>. The plan is flexible
              and you can always change your mind.
              <br />
              <br />
              What’s more, you can choose <strong>a monthly subscription</strong> instead of the
              annual one. You don’t have to commit for a whole year, and you don’t have to pay for
              12 months at once. The subscription can be tailored to your needs.
            </p>
            <div className="buttons">
              <Button secondary signUp sourceID="pricing">
                Sign up free
              </Button>
              <Link withArrow href="/pricing/">
                Read more
              </Link>
            </div>
          </div>
        </Section>
        <CompareTo
          LSbase={LIVESESSION}
          compareBase={FULLSTORY}
          dataSetBase={dataSet}
          compareTo="FullStory"
          icon={icon}
          title="LiveSession vs. FullStory"
          desc="Here’s why LiveSession might be a better alternative."
        />
        <Banner
          title={
            <h2 className="line">
              By choosing <span>LiveSession</span> instead of FullStory you can save over
            </h2>
          }
          desc="How did we calculate it? 100k sessions in FullStory cost $10200. With LiveSession it’s
          only $5089."
        />
        <KeyAdvantages />
        <Opinion
          opinion="LiveSession really listens to customer feedback and answers our needs. The app works smoothly with complex SPA applications and it truly helps us improve user experience."
          companyID="livereacting"
        />
        <CustomersRate className="hero-page" />
        <FAQ questions={questions} twoColumns />
        <Divider />
      </div>
      <TrustedBy customText="Trusted by" />
      <StartUsing />
    </Layout>
  );
};

export default FullStoryCompare;
